.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-marker-icon{ 
  margin-left: -20px !important;
  margin-top: -41px !important;
  width: 41px !important;
  height: 41px !important;  
}

.leaflet-marker-shadow {
  display: none !important;
}

/* General styles */
header {
  background: #000;
  color: white;
  padding: 15px 20px;
  border-bottom: 1px solid white;
}

nav {
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.menu-toggle {
  font-size: 30px;
  cursor: pointer;
  display: none; /* Hidden by default on large screens */
}

/* Navigation menu */
.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  transition: 0.3s;
}

.nav-links a:hover {
  color: #f4a261;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .menu-toggle {
    display: block; /* Show hamburger icon on small screens */
  }

  .nav-links {
    display: none;
    flex-direction: column;
    background: #444;
    position: absolute;
    top: 60px;
    right: 0;
    width: 200px;
    padding: 10px;
    border-radius: 5px;
  }

  .nav-links.show {
    display: flex;
  }

  .nav-links li {
    padding: 10px;
    text-align: center;
  }
}

body { 
  margin: 0;
  background-color: #000;
  color: #fff;
}

.content {
  margin: 10px;
}

.content:has(> .App) {
  margin: 0;
}


iframe {
  width: 100vw;
  height: 100vh;
  border: none;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img.popup-thumbnail {
  width: 196px;
}

a {
  color: #fff;
}

img.leaflet-marker-icon[alt="live"] {
 
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

.ant-tabs-tab{
  color: #fff !important;
}

.ant-picker-now-btn {
  color: #000 !important;
}

.auth_button_signUp {
  color: black !important;
  background-color: white !important;
  /* border-radius: 0 !important; */
}

.login_submit_btn,
.profile_save_btn {
  color: white !important;
  background-color: #000 !important;
  /* border-radius: 0 !important; */
}

.ant-typography a {
  color: black !important;
  font-weight: bold !important;
}

.iframe-container {
  position: relative;
  width: 99%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (9 / 16 * 100) */
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}

main a {
  color: #000;
}

.content-image {
  max-width: 99%;
  border: 1px solid;
  border-radius: 3px;
  box-shadow: 6px 6px 10px #0005;
}